module.exports = [{
      plugin: require('../.yarn/$$virtual/gatsby-plugin-manifest-virtual-97e71ce2a8/0/cache/gatsby-plugin-manifest-npm-2.4.37-294581bed4-e269f9117f.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"HomeClix Admin UI","short_name":"HomeClix Admin","start_url":"/","background_color":"#9a0b1a","theme_color":"#9a0b1a","display":"minimal-ui","icon":"src/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"8d115da0a3d3a449b33382d37c04d9ac"},
    },{
      plugin: require('../.yarn/$$virtual/gatsby-theme-material-ui-top-layout-virtual-69b9c9b9e1/0/cache/gatsby-theme-material-ui-top-layout-npm-1.0.3-b086438656-2ae7773157.zip/node_modules/gatsby-theme-material-ui-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/$$virtual/gatsby-plugin-material-ui-virtual-5b3fc701d8/0/cache/gatsby-plugin-material-ui-npm-2.1.10-b8f9cb8bdc-32a12c93b5.zip/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
